// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/vinayan3/Workspace/bite-api/frontend/developer-portal/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-forgot-password-js": () => import("/Users/vinayan3/Workspace/bite-api/frontend/developer-portal/src/pages/forgot_password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("/Users/vinayan3/Workspace/bite-api/frontend/developer-portal/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("/Users/vinayan3/Workspace/bite-api/frontend/developer-portal/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () => import("/Users/vinayan3/Workspace/bite-api/frontend/developer-portal/src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-reset-password-js": () => import("/Users/vinayan3/Workspace/bite-api/frontend/developer-portal/src/pages/reset_password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */)
}

exports.data = () => import("/Users/vinayan3/Workspace/bite-api/frontend/developer-portal/.cache/data.json")

