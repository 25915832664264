/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require('babel-polyfill')
require('regenerator-runtime/runtime')

require('./src/css/reset.css')

// found here https://github.com/PrismJS/prism-themes
require('./src/css/prism-duotone-light.css')
require('./src/css/gatsby-markdown.css')

require("@blueprintjs/core/lib/css/blueprint.css")
require("@blueprintjs/icons/lib/css/blueprint-icons.css")


// You can delete this file if you're not using it