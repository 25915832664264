module.exports = [{
      plugin: require('/Users/vinayan3/Workspace/bite-api/frontend/developer-portal/node_modules/gatsby-remark-autolink-headers/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/vinayan3/Workspace/bite-api/frontend/developer-portal/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/vinayan3/Workspace/bite-api/frontend/developer-portal/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-76149572-3","head":true,"anonymize":true,"respectDNT":true,"siteSpeedSampleRate":10},
    },{
      plugin: require('/Users/vinayan3/Workspace/bite-api/frontend/developer-portal/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
